* {
    text-transform: uppercase;
}


#resto {
    position: absolute;
    z-index: 9;
}

.moving_div {
    /* margin-top: calc(70vh + 186px); */
    margin-top: calc(85vh);
    z-index: 1;
    position: relative;
}

.fixed_div {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.tecido {
    background-image: url("./imgs/assets/background_pattern_light@2x.png");
}

.listenTo {
    width: 100%;
    position: absolute;
    /* filter: blur(4px); */
    mix-blend-mode: difference;
    z-index: 501;

}

.secret {
    z-index: 0;
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    font: normal normal 600 31px/38px Rama Gothic E;
}

.secret iframe {
    margin: 0 auto;
    background-color: #777;
    mix-blend-mode: difference;
    border-style: 0px;
}
.secret .legenda {
    color: #fff;
    font-size: 20px;
    padding-top: 44px;
    padding-bottom: 20px;
    line-height: 31px;
}


.secretMobile { 
    display: none;
}

.blur {
    background-color:rgba(0,0,0,0.35);
}

.footerLogo {
    width: 40%;
    max-width: 400px;
}

@media ( max-width: 650px) {
    .moving_div {
        margin-top: calc(80vh);
        z-index: 1;
        position: relative;
    }

    .secret { 
        display: none;
    }

    .secretMobile {
        display: block;
        z-index: 0;
        position: absolute;
        bottom: 0px;
        width: 100%;
        text-align: center;
        color: #fff;
    }

    .secretMobile iframe {
        padding-bottom: 10px;
    }

    
    .secretMobile .legenda {
        font: normal normal 600 16px/19px Rama Gothic E;
        text-align: center;
        padding: 20px 63px;
        mix-blend-mode: difference;
    }

}