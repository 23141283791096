.fundo {
    background-image: url("../../imgs/assets/background_pattern_light@2x.png");
    width: 100%;
    z-index: 2;
}

.risco {
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 3;
    width: 300px;
}