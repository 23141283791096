@media screen and (min-width: 1201px)  {
    .imgRippedTop {
        z-index: 2;
        margin-top: 30%;
        width: "100%";
        transform: "translateY(6px)"
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .imgRippedTop {
        z-index: 2;
        margin-top: 30%;
        width: "100%";
        transform: "translateY(6px)"
    }
}

@media screen and (min-width: 651px) and (max-width: 900px) {
    .imgRippedTop {
        z-index: 2;
        margin-top: 30%;
        width: "100%";
        transform: "translateY(6px)"
    }
}

@media ( max-width: 650px) {
    .imgRippedTop {
        margin-top: 630px;
    }
}