@media screen and (min-width: 1301px)  {
    .logo {
        width: 42.5%;
        max-width: 600px;
    }

    .linksHeader {
        padding-top: 30px;
        padding-left: 21px;
        padding-right: 21px;
        display: flex;
        justify-content: space-between;
        mix-blend-mode: difference;
    }

    .linksHeader div {
        color: white;
        display: flex;
        font: normal normal 600 31px/38px Rama Gothic E;
        letter-spacing: 0px;
        text-transform: uppercase;
        padding-left: 0;
        line-height: 31px;

        margin-left: 40px;
        margin-right: 40px;
    }

    .textarea {
        margin-left: 20px;
        margin-right: 20px;
    }
        
    .mobileHeader {
        display: none;
    }

}

@media screen and (min-width: 1001px) and (max-width: 1300px) {
    .logo {
        width: 37.5%;
    }
    
    .linksHeader {
        padding-top: 30px;
        padding-left: 21px;
        padding-right: 21px;
        display: flex;
        justify-content: space-between;
        mix-blend-mode: difference;
    }
    
    .linksHeader div {
        color: white;
        display: flex;
        font: normal normal 600 25px/32px Rama Gothic E;
        text-transform: uppercase;
        padding-left: 0;
        
        margin-left: 30px;
        margin-right: 30px;
    }    
    
    .mobileHeader {
        display: none;
    }
}

@media screen and (min-width: 651px) and (max-width: 1000px) {
    .logo {
        width: 35%;
    }
    
    .linksHeader {
        padding-top: 30px;
        padding-left: 21px;
        padding-right: 21px;
        display: flex;
        justify-content: space-between;
        mix-blend-mode: difference;
    }
    
    .linksHeader div {
        color: white;
        display: flex;
        font: normal normal 600 18px/25px Rama Gothic E;
        text-transform: uppercase;

        margin-left: 20px;
        margin-right: 20px;
    }

    .mobileHeader {
        display: none;
    }
}


@media ( max-width: 650px) {
    .linksHeader {
        display: none;
    }

    .mobileHeader {
        display: block;

        text-align: center;
        font: normal normal 600 20px/25px Rama Gothic E;
        text-transform: uppercase;
        mix-blend-mode: difference;
    }

    .mobileHeader .logoMobile {
        max-width: 90%;
        padding-top: 20px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .mobileHeader .linksHeaderMobile{
        color: white;
        font: normal normal 600 20px/25px Rama Gothic E;
    }

    .mobileHeader .linksHeaderMobile .a{
        color: white;
        font: normal normal 600 20px/25px Rama Gothic E;
        text-decoration: none;

    }

    .linksHeaderMobile {
        padding-top: 50vh;
    }
    
}