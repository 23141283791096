.wrapper {
    background-image: url("../imgs/assets/background_pattern_light@2x.png");
}

#ImgWrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    perspective: 200px;
    z-index: 3;
}

#pinIt {
    top: 0;
    left: 0;
    width: 100vw;
    height: 300vh; /* mudar aqui para aumentar o tamanho do fundo dos posters */
    overflow: hidden;
    position: relative !important;
    background: var(--ColorDarkA);
}

#ImgWrapper img {
    width: 100%;
    box-shadow: 0 0 31px black;
}

#ImgWrapper div {
    position: absolute;
}

#ImgWrapper #img01 {
    z-index: 14;
    bottom: 39vh;
    left: 55%;
    height: 35vh;
    max-width: 13%;
}

#ImgWrapper #img02 {
    z-index: 13;
    height: 31vh;
    bottom: 28vh;
    right: 55%;
    max-width: 13%;
}

#ImgWrapper #img03 {
    z-index: 12;
    height: 32vh;
    bottom: 38vh;
    left: 35%;
    max-width: 13%;
}

#ImgWrapper #img04 {
    z-index: 11;
    height: 31vh;
    bottom: 31vh;
    right: 34%;
    max-width: 13%;
}

#ImgWrapper #img05 {
    z-index: 10;
    bottom: 38%;
    left: 39%;
    width: 10vw;
}

#ImgWrapper #img06 {
    z-index: 9;
    bottom: 45%;
    right: 39%;
    width: 10vw;
}

#ImgWrapper #img07 {
    z-index: 8;
    bottom: 37%;
    right: 51%;
    height: 24vh;
    width: 10vw;
}

#ImgWrapper #img08 {
    z-index: 7;
    bottom: 22%;
    right: 38%;
    height: 30vh;
    width: 10vw;
}

#ImgWrapper #img09 {
    z-index: 6;
    bottom: 24%;
    left: 39%;
    height: 25vh;
    width: 10vw;
}

#ImgWrapper #img10 {
    z-index: 5;
    bottom: 30%;
    right: 39%;
    height: 30vh;
    width: 10vw;
}

#ImgWrapper #img11 {
    z-index: 4;
    bottom: 24%;
    left: 45%;
    height: 37vh;
    width: 10vw;
}

.sliderMobile {
    display: none;
}

@media (max-width: 650px) {
    .slider {
        display: none;
    }

    .sliderMobile {
        display: block;
        text-align: center;
        padding-bottom: 90px;
        padding-top: 90px;
    }

    .sliderMobile img {
        max-width: 70%;
        margin-bottom: 43px;
    }
    
}
