a {
    text-decoration: none;
    color: inherit;
}

@media screen and (min-width: 1201px)  {
    .grupo {
        font: normal normal 600 73px/63px Rama Gothic E;
        line-height: 31px;
        padding: 6% 15%;
        text-transform: uppercase;
        margin-top: 10%;
        display: flex;
        justify-content: space-between;
        z-index: 700;
        color: white;
    }
    
    .right-side {
        padding-top: 4.5%;
    }
    
    .title {
        font-size: 70px;
        line-height: 65px;
        padding-bottom: 15px;
        line-height: 63px;
        margin-right: 50px;
        max-width: 500px;
    }

    .subtitle {
        font-size: 20px;
    }

    .email {
        font-size: 42px;
        padding-bottom: 30px;
    }
    
    
    .link {
        font-size: 31px;
    }

    .grupoMobile { 
        display: none;
    }

}

@media screen and (min-width: 901px) and (max-width: 1200px) {
    .grupo {
        font: normal normal 600 25px/32px Rama Gothic E;
        line-height: 31px;
        padding: 8% 10%;
        margin-top: 5%;
        margin-bottom: 10%;
        padding-bottom: 5%;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        z-index: 700;
        color: white;
    }
    
    .right-side {
        padding-top: 62px;
    }
    
    .title {
        font-size: 50px;
        line-height: 65px;
        padding-bottom: 15px;
        line-height: 63px;
        margin-right: 40px;
    }

    .subtitle {
        font-size: 22px;
    }

    .email {
        font-size: 36px;
        padding-bottom: 30px;
    }
    
    
    .link {
        font-size: 27px;
    }

    .grupoMobile { 
        display: none;
    }
}

@media screen and (min-width: 801px) and (max-width: 900px) {
    .grupo {
        font: normal normal 600 25px Rama Gothic E;
        padding: 6% 15%;
        margin-top: 5%;
        padding-bottom: 5%;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        z-index: 700;
        color: white;
    }
    
    .right-side {
        padding-top: 62px;
    }
    
    .title {
        font-size: 40px;
        padding-bottom: 15px;
        line-height: 43px;
        margin-right: 30px;
    }

    .subtitle {
        font-size: 12px;
    }

    .email {
        font-size: 26px;
        padding-bottom: 30px;
    }
    
    
    .link {
        font-size: 20px;
    }
    
    .grupoMobile { 
        display: none;
    }
}

@media screen and (min-width: 651px) and (max-width: 800px) {
    .grupo {
        font: normal normal 600 15px Rama Gothic E;
        padding: 6% 10%;
        margin-top: 5%;
        padding-bottom: 5%;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        z-index: 700;
        color: white;
    }
    
    .right-side {
        padding-top: 62px;
    }
    
    .title {
        font-size: 30px;
        padding-bottom: 15px;
        line-height: 33px;
        margin-right: 30px;
    }

    .subtitle {
        font-size: 12px;
    }

    .email {
        font-size: 26px;
        padding-bottom: 30px;
    }
    
    
    .link {
        font-size: 20px;
    }
    
    .grupoMobile { 
        display: none;
    }
}

@media screen and (min-width: 401px) and (max-width: 650px) {
    .grupo {
        display: none;
    }

    .grupoMobile {
        display: block;
        padding-top: 160px;
        padding-bottom: 80px;
        padding-left: 72px;
        padding-right: 72px;
        
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0px;

        mix-blend-mode: difference;
    }
        
    .grupoMobile .title {
        font: normal normal 600 50px/46px Rama Gothic E;
        padding-bottom: 10px;
    }

    .grupoMobile .grupoLinks {
        padding-bottom: 15px;
    }
    
    .grupoMobile .grupoLinks .link {
        font: normal normal 600 20px/25px Rama Gothic E;
    }

    .grupoMobile .subtitle {
        font: normal normal 600 16px/31px Rama Gothic E;
    }

    .grupoMobile .grupoEmails .email {
        font: normal normal 600 25px/25px Rama Gothic E;
    }
    
    .grupoMobile .grupoEmails  {
        padding-top: 70px;
    }
}

@media ( max-width: 400px) {

    .grupo {
        display: none;
    }

    .grupoMobile {
        display: block;

        padding-top: 160px;
        padding-bottom: 80px;
        padding-left: 32px;
        padding-right: 32px;
        
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0px;

        mix-blend-mode: difference;
    }
        
    .grupoMobile .title {
        font: normal normal 600 40px/46px Rama Gothic E;
        padding-bottom: 30px;
    }

    .grupoMobile .grupoLinks {
        padding-bottom: 25px;
    }
    
    .grupoMobile .grupoLinks .link {
        font: normal normal 600 20px/25px Rama Gothic E;
    }

    .grupoMobile .subtitle {
        font: normal normal 600 16px/31px Rama Gothic E;
    }

    .grupoMobile .grupoEmails .email {
        font: normal normal 600 25px/25px Rama Gothic E;
    }
    
    .grupoMobile .grupoEmails  {
        padding-top: 70px;
    }

}


.linkanim a {
    color: #ffffff;
    position: relative;
    text-decoration: none;
    transition: color 0.4s ease-out;
}

.linkanim a:hover {
    color: #ffffff;
    right: 0;
    text-decoration: none;
}

.linkanim a:hover:after {
    border-color: #ffffff;
    right: 0;
}

.linkanim a:after {
    border-radius: 1em;
    border-top: 0.1em solid #ffffff;
    content: "";
    position: absolute;
    right: 100%;
    bottom: 0.14em;
    left: 0;
    transition: right 0.4s cubic-bezier(0, 0.5, 0, 1),
        border-color 0.4s ease-out;
}

.linkanim a:hover:after {
    animation: anchor-underline 2s cubic-bezier(0, 0.5, 0, 1) infinite;
    border-color: #ffffff;
}

@keyframes anchor-underline {
    0%,
    10% {
        left: 0;
        right: 100%;
    }
    40%,
    60% {
        left: 0;
        right: 0;
    }
    90%,
    100% {
        left: 100%;
        right: 0;
    }
}
