::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'Rama Gothic E';
  src: local('Rama Gothic E'), url("../fonts/Rama-Gothic-E-W01-Regular.ttf") format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

